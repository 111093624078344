<template>
  <div class="topic_warp"
       ref="topicWrapRef">
    <TopCard ref="TopCardRef"
             text="做题详情" />

    <!-- 导出试卷按钮 -->
    <ExportPaper v-if='paperInfo.user_paper_id'
                 :id='paperInfo.user_paper_id' />
    <div v-if='paperInfo.user_paper_id'
         class="paper_id">试卷ID:{{paperInfo.user_paper_id}}</div>

    <div v-if='paperInfo.source_note'
         class="note"
         style="padding:5rem 40rem;font-size:20rem">备注:{{paperInfo.source_note}}</div>

    <div class="wraper">
      <div class="topic_list"
           ref="imageWrapper">
        <Dopaper ref="DopaperRef"
                 v-for="question in paperInfo.questions"
                 :key="question.question_id"
                 @setAnwer="setAnwer"
                 :question="question" />

        <div class="image_wrap">
          <p class="title">请添加图片:<span>
              （最多可添加6张图片）
            </span>
          </p>
          <div class="img_list">
            <div class="img_wrap"
                 v-for="(item,index) in images"
                 :key="index">
              <img class="del"
                   @click="del(index)"
                   src="@/assets/icons/删除.png"
                   alt="">
              <img class="answer"
                    :src="$addForHeader(item)"
                   alt="">
            </div>
            <div class="add_img"
                 v-if="images.length  != 6"
                 @click="uploadImage">
              <div>
                <img src="@/assets/add_img.png"
                     alt="">
              </div>
              点击上传
            </div>
            <div v-if="1"
                 @click="btnTakePhotoClicked">
              <video ref="video"
                     autoplay
                     width="400"
                     height="300"></video>
            </div>

            <!-- <button @click="btnTakePhotoClicked()">Take photo</button> -->
            <canvas ref="canvas"
                    width="400"
                    style="display:none"
                    height="300"></canvas>

            <a href=""
               style="display:none"
               download="拍摄图片.jpeg"
               id="save_herf">
              <img src=""
                   id="save_img"
                   alt="">
            </a>

          </div>
          <Code ref="codeRef" />
        </div>
        <div class="btn_warp">
          <div class="submit"
               @click="showSubmitDialog">
            提交
          </div>
        </div>
      </div>
    </div>

    <CommenToUser ref="CommenToUserRef" />

    <RightScroll ref="RightScrollRef"
                 :topic_numer="topicNumber" />
    <Hint ref="hintRef" />
    <SubmitHint ref="SubmitHintRef" />

  </div>
</template>

<script>
import Dopaper from '@/views/components/topic/index.vue'
import TopCard from '@/views/components/top_card/index.vue'
import { doPaper, saveAnswer, submitUserPaper } from '@/api/paper_template.js'
import RightScroll from '@/views/components/right_card/index.vue'
import { continueDoPaper } from '@/api/my_paper.js'
import { checkPointTrain } from '@/api/checkpoint.js'
import { getTeacherList } from '@/api/choiceness.js'
import { knowledgeTrain } from '@/api/knowledge.js'
import { capabilityTrain } from '@/api/capability.js'
import { doWholePaper } from '@/api/wholepaper.js'
import { lectureDoPaper } from '@/api/handout.js'
import CommenToUser from '@/views/paper_components/correct/components/comment.vue'
import { uploadFile, uploadImageAuto } from '@/api/upload.js'
import Hint from './components/hint.vue'
import SubmitHint from '../dopaper_card/components/hint.vue'
import { chapterTrain } from '@/api/chapter.js'
import Code from '@/views/components/code/index.vue'
import ExportPaper from '@/views/components/exportPaper/index.vue'//导出试卷按钮


export default {
  data () {
    return {
      images: [],
      paperInfo: {},
      flatList: {},
      topicNumber: 0,
      isSubmit: true
    }
  },
  components: {
    Dopaper, TopCard, RightScroll, CommenToUser, Hint, SubmitHint, Code, ExportPaper
  },
  watch: {
    'paperInfo.user_paper_id': {
      handler () {
        this.$refs.codeRef.creatQrCode(this.paperInfo.user_paper_id)
      },
      deep: true
    }
  },
  mounted () {
    var paper_info = window.sessionStorage.getItem('paper_info')
    if (paper_info) {
      this.paperInfo = JSON.parse(paper_info)
      this.setFlatAndTopicNumber(this.paperInfo)
      let topicIndex = window.sessionStorage.getItem('topic_index')
      this.images = JSON.parse(window.sessionStorage.getItem('image'))
      if (topicIndex) {
        this.scrollToIndex(topicIndex)
      } else {
        this.$nextTick(() => {
          document.getElementsByClassName('topic_warp')[0].scrollTop = window.sessionStorage.getItem('scroll_top')
        })
      }
      return
    }
    var type = this.$route.query.type
    //后面使用switch改写
    if (type == 2) {
      var form = JSON.parse(this.$route.query.form)
      this.countinueDoPaper(form)
    } else {
      this.$nextTick(() => {
        this.$refs.hintRef.dialogVisible = true
      })
    }
    // setTimeout(() => {
    // }, 1500);
    // this._initVueApp();


  },
  // 离开前做处理
  beforeDestroy () {
    if (this.isSubmit) {
      this.savePaperAnswer()
    }
  },
  methods: {
    // async _initVueApp () {
    //   try {
    //     this.$refs.video.srcObject = await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
    //   } catch (error) {
    //     console.log("error", error)
    //     this.$message.error("摄像头调用失败")
    //   }

    //   this._context2d = this.$refs.canvas.getContext("2d");

    //   this.canvas = this.$refs.canvas;
    // },

    async _initVueApp () {
      try {
        // 获取设备列表
        const devices = await navigator.mediaDevices.enumerateDevices();
        let hasCamera = false;
        console.log("devices", devices);
        // 遍历设备列表，判断是否存在摄像头设备
        for (const device of devices) {
          console.log("device", device.kind);
          if (device.kind === 'videoinput') {

            hasCamera = true;
            break;
          }
        }
        console.log("hasCamera", hasCamera);
        if (hasCamera) {
          this.$refs.video.srcObject = await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
          this._context2d = this.$refs.canvas.getContext("2d");
          this.canvas = this.$refs.canvas;
        } else {
          // this.$message.error("电脑设备没有摄像头");
        }
      } catch (error) {
        console.log('error', error)

        this.$message.error("摄像头调用失败");
      }
    },
    btnTakePhotoClicked () {

      this._context2d.drawImage(this.$refs.video, 0, 0, 400, 300)
      var img = document.createElement("img"); // 创建img元素
      img.src = this.canvas.toDataURL("image/png"); // 截取视频第一帧
      var svaeHref = document.getElementById("save_herf");
      console.log(img.src)

      var sd = document.getElementById("save_img");
      svaeHref.href = img.src;
      sd.src = img.src
      svaeHref.click()

      this.uploadImage()


    },
    showPaper () {
      this._initVueApp()
      var type = this.$route.query.type
      var form = JSON.parse(this.$route.query.form)
      // 1 组卷测试 2继续做卷 3考点做卷 4精选试卷 5知识点训练 6学校整卷-整卷测试-考点做卷,7讲义做卷,8章节训练
      // if (type == 1) {
      //   console.log("1111")
      //   console.log(form)
      //   this.groupTest(form)
      // } else if (type == 2) {
      // } else if (type == 3) {
      //   this.checkPointTrain(form)
      // } else if (type == 4) {
      //   this.getTeacherPaper(form)
      // } else if (type == 5) {
      //   this.getKnowledgePaper(form)
      // } else if (type == 6) {
      //   this.getWholePaper(form)
      // } else if (type == 7) {
      //   this.getLecturePaper(form)
      // }
      // else if (type == 8) {
      //   this.getChapterPaper(form)
      // }

      switch (type) {
        case "1":
          this.groupTest(form);
          break;
        case "2":
          break;
        case "3":
          this.checkPointTrain(form);
          break;
        case "4":
          this.getTeacherPaper(form);
          break;
        case "5":
          this.getKnowledgePaper(form);
          break;
        case "6":
          this.getWholePaper(form);
          break;
        case "7":
          this.getLecturePaper(form);
          break;
        case "8":
          this.getChapterPaper(form);
          break;
        case "17":
          this.getCapabilityPaper(form);
          break;
        case "18":
          this.getCapabilityPaper(form);
          break;
        default: console.log(type)
          break;
      }

    },
    scrollToIndex (index) {
      this.$nextTick(() => {
        this.$refs.RightScrollRef.toMaodian(index)
      })
    },
    // 离开页面前保存答案
    async savePaperAnswer () {
      if (!this.paperInfo.user_paper_id) return
      let data = {
        user_paper_id: this.paperInfo.user_paper_id,
        user_answer: {},
        other_answer: JSON.stringify(this.images)
      }
      var flatList = Object.values(this.flatList)
      flatList.forEach(item => {
        if (Number(item.question_score) && item.answer.length) {
          data.user_answer[item.question_id] = item.answer.join(',')
        }
      })
      await saveAnswer(data)
    },
    toCard () {
      var list = {};
      var group_list = [];
      Object.values(this.flatList).forEach(item => {
        list[item.answer_id] = item
      })

      Object.values(list).forEach(item => {
        if (Number(item.question_score)) {
          group_list.push(item)
        }
      })

      // 获取当前距离顶部距离，返回来的时候定位使用
      var oapp = document.getElementsByClassName('topic_warp')[0]
      window.sessionStorage.setItem('scroll_top', oapp.scrollTop)
      window.sessionStorage.setItem('paper_info', JSON.stringify(this.paperInfo))
      window.sessionStorage.setItem('paper_list', JSON.stringify(group_list))
      window.sessionStorage.setItem('image', JSON.stringify(this.images))
      this.$router.push('/dopaper_card?type=group')
    },
    async groupTest (form) {
      const { data } = await doPaper(form)
      this.publicQuestionsFunc(data)
    },
    publicQuestionsFunc (data) {
      data.images = []
      data.questions = data.questions.map(item => {
        item.question_big_score = 0;
        item.topic += ''
        item.topic = Number(item.topic)
        if (item.question_children && item.question_children.length) {
          let score = 0;
          item.question_children = item.question_children.map(element => {
            score += Number(element.question_score);
            element.topic = Number(element.topic)
            element.answer = [];
            return element;
          });
          item.question_big_score = score;
        } else {
          item.answer = [];
        }
        return item;
      })
      this.paperInfo = data;
      this.setFlatAndTopicNumber(this.paperInfo);
    },
    setFlatAndTopicNumber (info) {
      // 扁平化 引用this的question 也就是说flatlist 变化 paperInfo.questions也要跟着变
      var list = this.flatQuestion(info.questions)
      var obj = {}
      var topicNumber = 0;
      list.forEach(item => {
        obj[item.question_id] = item
        if (Number(item.question_score)) {
          topicNumber += 1;
        }
      });
      this.flatList = obj

      // 获取共有多少题目，然后传递给右侧组件
      this.topicNumber = topicNumber
    },
    flatQuestion (list) {
      var allList = [];
      list.forEach(element => {
        allList.push(element)
        if (element.question_children && element.question_children) {
          allList = allList.concat(...element.question_children);
        }
      });
      return allList;
    },
    setAnwer (id, answer) {
      var question = this.flatList[id]
      // 重复点击 取消选中
      if (question.answer.indexOf(answer) != -1) {
        question.answer.splice(question.answer.indexOf(answer), 1)
        return
      }
      question.topic = Number(question.topic)

      if (question.topic == 1 || question.topic == 9) {
        question.answer = [answer];
      } else if ([2, 3, 4].indexOf(question.topic) != -1) {
        //双选
        if (question.answer.length == 2 && question.topic == 4) {
          return
        }
        question.answer.push(answer)
      }
    },
    async countinueDoPaper (form) {
      const { data } = await continueDoPaper(form)
      console.log(data.list)
      // data.list.images = data.list.other_answer ? data.list.other_answer.split(',') : []
      this.images = data.list.other_answer ? JSON.parse(data.list.other_answer) : []
      // let arr = this.images.map(item => {
      //   return './' + item
      // })
      // this.images = arr
      data.list.questions = data.list.questions.map(item => {
        item.question_big_score = 0;

        if (item.question_children && item.question_children.length) {

          let score = 0
          item.question_children = item.question_children.map(element => {
            score += Number(element.question_score);
            element.answer = []
            if (element.user_answer) {
              element.answer = element.user_answer.split(',')
            }
            return element
          });
          item.question_big_score = score;
        } else {
          item.answer = []
          if (item.user_answer) {
            item.answer = item.user_answer.split(',')
          }
        }
        return item;
      })
      // if (data.list.images.length) {
      //   window.sessionStorage.setItem('image', JSON.stringify(data.list.images))
      // }
      this.paperInfo = data.list
      this.setFlatAndTopicNumber(this.paperInfo)
    },
    async checkPointTrain (form) {
      const { data } = await checkPointTrain(form)
      this.publicQuestionsFunc(data.list)
    },
    async getTeacherPaper (form) {
      const { data } = await getTeacherList(form)
      this.publicQuestionsFunc(data.list)
    },
    async getCapabilityPaper (form) {
      const { data } = await capabilityTrain(form)
      this.publicQuestionsFunc(data.list)
    },
    async getKnowledgePaper (form) {
      const { data } = await knowledgeTrain(form)
      this.publicQuestionsFunc(data.list)
    },
    async getWholePaper (form) {
      const { data } = await doWholePaper(form)
      this.publicQuestionsFunc(data.list)
    },
    async getLecturePaper (form) {
      const { data } = await lectureDoPaper(form)
      this.publicQuestionsFunc(data.list)
    },
    async submitPaper () {
      let data = {
        user_paper_id: this.paperInfo.user_paper_id,
        chs_answer: {},
        other_answer: this.images
      }


      var list = {};
      var group_list = [];
      Object.values(this.flatList).forEach(item => {
        list[item.answer_id] = item
      })

      Object.values(list).forEach(item => {
        if (Number(item.question_score)) {
          group_list.push(item)
        }
      })

      group_list.forEach(item => {
        console.log('item)', item)
        // console.log('data')
        if (item.answer && item.answer.length) {
          data.chs_answer[item.answer_id] = item.answer.join(',')
        }
      });

      console.log('group_list)', group_list)
      data.other_answer = JSON.stringify(data.other_answer)
      await submitUserPaper(data)
      this.isSubmit = false
      this.$message.success('提交成功!可前往我的试卷查看')
      this.$router.go(-1)
      this.$removePaperInfo()
    },
    del (index) {
      this.images.splice(index, 1)
    },
    async uploadImage () {
      const { data } = await uploadImageAuto()
      this.images = Object.values(this.images);
      this.images.push(data.info.name)
      this.$message.success('上传成功')
    },
    showSubmitDialog () {
      var group_list = [];
      var list = []
      Object.values(this.flatList).forEach(item => {
        list[item.answer_id] = item
      })

      Object.values(list).forEach(item => {
        if (Number(item.question_score)) {
          group_list.push(item)
        }
      })

      var num = 0;
      group_list.forEach(item => {
        var idx = this.$checkArr.findIndex(topic => topic == item.topic)
        if ((idx != -1 && item.answer.length == 0) || (idx == -1 && this.images.length == 0)) {
          num += 1;
        }
      })

      this.$refs.SubmitHintRef.num = num
      this.$refs.SubmitHintRef.dialogVisible = true
    },
    async getChapterPaper (form) {
      const { data } = await chapterTrain(form)
      this.publicQuestionsFunc(data.list)
    },

  }
}
</script>

<style lang="scss" scoped>
.topic_warp {
  width: 100%;
  // min-height: 100vw;
  background: #f6f6f6;
  overflow-x: hidden;
  overflow-y: auto;
}
.paper_id {
  font-size: 20rem;
  padding: 10rem 40rem;
  font-weight: 700;
}

.wraper {
  height: calc(100vh - 200rem);
  overflow-y: auto;
}
.topic_list {
  // margin-top: -20rem;
}
.btn_warp {
  height: 120rem;
  .submit {
    width: calc(100vw - 200rem);
    margin-left: 40rem;
    margin-top: 50rem;
    margin-bottom: 50rem;
    font-size: 20rem;
    font-weight: bold;
    color: #ffffff;
    height: 60rem;
    background: #2196f3;
    border-radius: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.image_wrap {
  background: #ffffff;
  width: calc(100% - 200rem);
  margin-left: 20rem;
  margin-top: 20rem;
  padding: 30rem;
  box-shadow: inset 0rem -1rem 0rem 0rem rgba(229, 229, 229, 1);
  // height: 178rem;
  .title {
    font-size: 20rem;
    font-weight: bold;
    color: #333333;
    span {
      color: #848484;
    }
  }
  .add_img {
    width: 120rem;
    height: 120rem;
    background: #f6f6f6;
    border-radius: 10rem;
    font-size: 20rem;
    font-weight: 500;
    color: #2196f3;
    text-align: center;
    cursor: pointer;
    img {
      width: 40rem;
      height: 34rem;
      margin-top: 26rem;
      margin-bottom: 6rem;
    }
  }
  .img_list {
    margin-top: 30rem;
    display: flex;

    .img_wrap {
      position: relative;
      margin-right: 24rem;
      display: flex;
      width: 120rem;
      height: 120rem;
      .del {
        position: absolute;
        right: -15rem;
        top: -15rem;
        width: 30rem;
        height: 30rem;
        cursor: pointer;
      }
      .answer {
        border-radius: 10rem;
        width: 120rem;
        height: 120rem;
      }
    }
  }
}
</style>